
import { authHeader } from './auth-header.jsx';
import Cookies from 'universal-cookie';
const { AES, enc }=require('crypto-js')
const Config = require('config');


export const userService = {
    login,
    logout,
    login2faverify,
    logoutuser,
    forgot,
    decrypt,
    encrypt,
    getinfo
};

function getinfo(){
    const cookies = new Cookies();
    return new Promise((resolve)=> {
    decrypt(cookies.get('uid')).then((data)=>{

        resolve(data)
    }).catch(()=>{
      
        logoutuser()
    })

    })

}
//Encrypting text
function encrypt(text) {
    const cookies = new Cookies();
    return new Promise((resolve)=> {
        const cipherText = AES.encrypt(text, cookies.get('auth'));

  resolve (cipherText.toString())
    })
  }
  
  // Decrypting text
  function decrypt(text) {
    const cookies = new Cookies();
    return new Promise((resolve)=> {
      const  bytes = AES.decrypt(text,cookies.get('auth'))
       // console.log(bytes);
        const decrypted = bytes.toString(enc.Utf8);
   resolve(decrypted)
    })
  }


function login(username, password) {
    const cookies = new Cookies();
  
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(Config.API+`/authenticate`, requestOptions)
      .then(handleResponse)
      .then(user => {
        // login successful if there's a user in the response
        if (user) {
            // store user details and basic auth credentials in local storage 
            // to keep user logged in between page refreshes
            //user.authdata = window.btoa(username + ':' + password);
      
//

return user.data.twofa_enabled;
                    
             
        
            
          
            
        }
       
        
    })
}
function login2faverify(username, password,verifycode) {
    return new Promise((resolve)=> {
  const cookies = new Cookies();
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password,verifycode})
  };

 fetch(Config.API+`/authenticate2fa`, requestOptions)
    .then(handleResponse)
      .then(user => {
 
          console.log(user)
          // login successful if there's a user in the response
          if (user.status=='success') {
              // store user details and basic auth credentials in local storage 
              // to keep user logged in between page refreshes
              //user.authdata = window.btoa(username + ':' + password);
              cookies.set('auth',  window.btoa(username + ':' + password), { path: '/', expires: (new Date(Date.now()+3600000)),secure:true,sameSite:'strict' });
            
              encrypt(JSON.stringify(user)).then(async (data)=>{
               
                       cookies.set('uid', data, { path: '/', expires: (new Date(Date.now()+3600000)) ,secure:true,sameSite:'strict'});
     
                  getAssets().then(()=>{
//

resolve(user);
                      
                  })
              }).catch(function(err){
                  console.log('fail')
               resolve(user)
             })
              
            
              
          }else{       console.log('fail')
              resolve(user)}
         
          
      })
  })
}
function getAssets() {
    return new Promise( (resolve)=> {
    var apps="true"
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ apps })
    };

    fetch(Config.API+`/assets`, requestOptions)
      .then(handleResponse)
        .then(assets => {
            if (assets) {
                
                localStorage.setItem('assets', JSON.stringify(assets));
             
            
            
resolve()}
        })
        })
}

function forgot(username) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username})
    };

    return fetch(Config.API+`/api/users/forgot`, requestOptions)
      .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (user) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
              
            }

            return user;
        });
}
function logoutuser() {

window.location='/#/a/login-page'

}

function logout() {
    // remove user from local storage to log user out
    const cookies = new Cookies();
    cookies.remove('auth',{ path: '/' })
    cookies.remove('uid',{ path: '/' })
    localStorage.removeItem('assets');
     window.location='/#/a/login-page'
}
function handleErrorResponse(response) {

    return Promise.reject('Error');
}
function handleResponse(response) {
  
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

         // const error = (data && data.message);
          return Promise.reject('Username or password is incorrect');
        }

        return data;
    });
}