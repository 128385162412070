import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";
import { ExportToCsv } from 'export-to-csv';
import ReactTable from "react-table-6";
import Moment from 'react-moment';
import moment from 'moment';
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-table-6/react-table.css'
import IconImage from "components/Icon/icon.jsx";
import { userService } from 'helpers/user.service.jsx';
const Config = require('config');


class Transactions extends Component {




    constructor(props){
        super(props);


        
                this.state = {
                  loading: true,
                transactionsdatalist:[],
                from: undefined,
                to: undefined
                  
                }
                this.handleFromChange = this.handleFromChange.bind(this);
                this.handleToChange = this.handleToChange.bind(this);
              //  console.log('this.props', this.props)
            }
    
            showFromMonth() {
              const { from, to } = this.state;
              if (!from) {
                return;
              }
            if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
            }
            }
          
            handleFromChange(from) {
              // Change the from date and focus the "to" input field
         
              this.setState({ from });
   
            }
          
            handleToChange(to) {
              this.setState({ to }, this.showFromMonth);
            }
            
            
componentWillMount(){

    this.gettransactions();

  }
  export(){ 
    const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        filename:'Transactions',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: false,
        title: '',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: ['ref id','date time','wallet address','tag','transaction','price','payment_amount','total_payment','coin','status','currency','settled currency','settled payment','settled fee','type','note','source address']
      };
     
    const csvExporter = new ExportToCsv(options);



  csvExporter.generateCsv(this.state.transactionsdatalist);



  }
  gettransactions(){
    var startDate=this.state.from;
    var endDate=this.state.to;
    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);
    var mid=user.data.merchant_id;
    var token=user.token;
    var auid=user.data.user_id;
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'  ,'access-token': token,'mid':user.data.merchant_id,'auid': auid},
      body:JSON.stringify({startDate,endDate})
   };
      


    fetch(Config.API+'/api/transactions/',requestOptions)
    .then( this.handleResponse)
    .then( transactiondata => this.setState({ transactionsdatalist:transactiondata.data,loading:false}));
   
  })
  }

 

  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            userService.logout()
            
               return 'error'

          }
        

        }
       

        return data;
    });
}




  

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    const FORMAT = 'YYYY-MM-DD';




    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      return (
          row[id] !== undefined ?
          isNaN(row[id])
            ?  String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            : String(row[id]).startsWith(filter.value)
          :
              true
      );
    }
    


    if (this.state.loading) return <Loader />
    return (
         <div className="main-content">
        <Grid fluid>
          <Row>
          <Col lg={12}>   <h3 className="header">Payments Received</h3></Col>
<Col lg={12}>

          <div className="card">
      
             

          <div className="content">
         
          <div className="options"> <Button  bsStyle="default" onClick={() => this.export()}>
                       Export
                        <span className="btn-label btn-label-right">
                           <i className="fa fa-download " />
                        </span>
                      </Button>
            <div className="DateSelector">   <div className="InputFromTo">
        <DayPickerInput
          value={formatDate(new Date(),'YYYY-MM-DD')}
          placeholder="From"
      
          format={FORMAT}
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { after: to },
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.handleFromChange}
        />{' '}
        —{' '}
        <span className="InputFromTo-to">
          <DayPickerInput
            ref={el => (this.to = el)}
            value={formatDate(new Date(),'YYYY-MM-DD')}
   
            placeholder="To"
            format={FORMAT}
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 2,
            }}
            onDayChange={this.handleToChange}
          />
             <Button  bsStyle="default" onClick={() => this.gettransactions()}>
                       Go
                      
                      </Button>
        </span>
          
        
         
       
        
        </div></div></div>
                  <ReactTable
                  
                    data={this.state.transactionsdatalist}
                    filterable
                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row) }
                    columns={[
                      {
                     
                        Header: "Coin",
                
  
  
                        accessor: "coin",
                       Cell: row => {  if (row.value) {return <div alt={row.value}> <IconImage  coin={row.value} />{row.value}</div>}else{return <div></div>}},
                    
                      
                        
                      
  
                      
                      },{
                        Header: "Date",
                        accessor:"date_time",
                       
                        Cell: props => {
                          return <Moment format="YYYY-MM-D H:mm:ss">{props.value}</Moment>;
                        },
                      },
                      {
                        Header: "Status",
                        accessor: "status"
                      }
                      ,
                      {
                        Header: "Ref/ Invoice ID",
                        accessor: "ref_id"
                      },
                      {
                        
                        Header: "Wallet Address",
                        accessor: "address"
                      },
                      {
                        Header: "Tag",
                        accessor: "tag"
                      },
                      {
                        Header: "Transaction",
                        accessor: "txid"
                      },
                     
                      {
                        Header: "Payment Amount",
                        accessor: "payment_amount"
                      }
                      ,
              
                      {
                     
                        Header: "Currency",
                
  
  
                        accessor: "currency",
                       Cell: row => {  if (row.value) {return <div alt={row.value}> <IconImage  coin={row.value} />{row.value}</div>}else{return <div></div>}},
                    
                      
                        
                      
  
                      
                      },{
                        Header: "Price",
                        id: "price",
                        accessor: d => {return d.price==null
                          ? 0
                        : d.price },
                        Cell: props => props.value.toLocaleString(navigator.language, { minimumFractionDigits: 4 })
                      }
                      
              
                      ,
                      {
                     
                        Header: "Settled Currency",
                
  
  
                        accessor: "settled_currency",
                       Cell: row => {  if (row.value) {return <div alt={row.value}> <IconImage  coin={row.value} />{row.value}</div>}else{return <div></div>}},
                    
                      
                        
                      
  
                      
                      },
                      {
                        Header: "Settled Payment",
                        accessor: "settled_payment"
                      },
                      {
                        Header: "Settled Fee",
                        accessor: "settled_fee"
                      },
                      {
                        id:"total_payment",
                        Header: "Total Payment",
                     
                        accessor: d => {return d.total_payment==null
                          ? 0
                        : d.total_payment },
                        Cell: props => props.value.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                      },
                      {
                        Header: "Type",
                        accessor: "type"
                      },
                      {
                        Header: "Note",
                        accessor: "note"
                      },
                      {
                        Header: "Source Address",
                        accessor: "source_address"
                      }
                  
                     
                      
                      
                    ]}
                    noDataText= "No Payments Found"
                    defaultPageSize={10}
           
                    showPaginationBottom={true}
                    className="-striped -highlight"
                  />
                

               
          </div>
          </div></Col>
          </Row>
        </Grid>
      </div>









    );
  }
}

export default Transactions;