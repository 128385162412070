import React, { Component } from "react";
import { Grid, Col, Row} from "react-bootstrap";
import { ExportToCsv } from 'export-to-csv';
import ReactTable from "react-table-6";
import Moment from 'react-moment';
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import RequestPayment from "components/Balances/requestpayment.jsx";
import IconImage from "components/Icon/icon.jsx";
import { userService } from 'helpers/user.service.jsx';
const Config = require('config');


class Payments extends Component {




    constructor(props){
        super(props);

        
                this.state = {
                  loading: true,
                  paymentsdatalist:[],
                  paymentsdisplaydatalist:[],
                owed:"",
                paid:"",
                owedcad:"",
                paidcad:"",
                owedeur:"",
                paideur:"",
                owedgbp:"",
                paidgbp:"",
                  mid:"",
                  kyc:false,
                  selectData:[]
                }
               
              //  console.log('this.props', this.props)
            }
                
componentDidMount(){
//this.getPaymentsTotals();

userService.getinfo().then((data)=>{

  var user=JSON.parse(data);
    var mid=user.data.merchant_id;
    if (user.data.kyc=='Verified'){this.setState({kyc:true,mid:mid})}else{
this.setState({kyc:false})}


this.getDisplayBalances()

    })
  }
  

  getBalances(){
    this.setState({ downloading: true},()=>{
      userService.getinfo().then((data)=>{

        var user=JSON.parse(data);
    var mid=user.data.merchant_id;
    var token=user.token;
    var auid=user.data.user_id;

    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'  ,'access-token': token,"mid":mid,'auid': auid}
   };
      


    fetch(Config.API+'/api/balances',requestOptions)
    .then( this.handleResponse)
    .then( withdrawdata => this.setState({ paymentsdatalist:withdrawdata.data, downloading: false,mid:mid},()=>{if(this.state.paymentsdatalist!=''){
      this.export()
        }else{    this.setState({ paymentsdatalist:[{ coin: "", balance: 0 }]},()=>{this.export()})}
  
      }))
    })
  })
  }

  export(){ 


    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      filename:'Balances',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: false,
      title: '',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: ['Coin','Balance','$ Balance']
    };
   
  const csvExporter = new ExportToCsv(options);


csvExporter.generateCsv(this.state.paymentsdatalist)

    


}


getDisplayBalances(){
  this.setState({ loading: true},()=>{
    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);
  var mid=user.data.merchant_id;
  var token=user.token;
  var auid=user.data.user_id;

  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'  ,'access-token': token,"mid":mid,'auid': auid}
 };
    


  fetch(Config.API+'/api/displaybalances',requestOptions)
  .then( this.handleResponse)
  .then( withdrawdata => this.setState({ paymentsdisplaydatalist:withdrawdata.data, loading: false,mid:mid}))

})})
}


  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            userService.logout()
            
               return 'error'

          }
        

        }
       

        return data;
    });
}


  

  render() {
    const { loading,selectData,mid} = this.state;
   

    if (this.state.loading) return <Loader />
    return (
         <div className="main-content">
          
        <Grid fluid>
          <Row>

          <Col lg={12}> {this.state.kyc!=true?<Card 
      warning="true"
                  content={
                  <Row >
                    <Col  className="text-center">
                    
                    <i className="fas fa-exclamation-triangle"></i> Please <a  href={Config.VerifyUrl} target="_blank">Verify</a> your account. <a href={Config.VerifyUrl} target="_blank">Verify Now</a>
    
              
                   
                    </Col>
                  
                  </Row>
                }
              />:<div></div>}</Col>
          <Col lg={12}>  <h3>Account Balances</h3>
        <div><div className="options"> <Button  bsStyle="default" onClick={() => this.getBalances()}>
                              Export
                               <span className="btn-label btn-label-right">
                                  <i className="fa fa-download " />
                               </span>
                             </Button>
                             </div>
                          </div>
    </Col>
    <Col lg={12}> 
    <ReactTable
                  
                  data={this.state.paymentsdisplaydatalist}
            
                  columns={[
                  
                    {
                      id:"coin",
                      Header: "Coin",
              


                      accessor: d =>  <div alt={d.coin}> <IconImage  coin={d.coin} />{d.coin}</div>
                     
                    

                    
                    },
                    {
                      Header: "Balance",
                      id: "balance",
                      accessor: d =>  <div >{d.balance} <br/><div className="small">(${d.usd})</div></div>,
                      Cell:"balance"
                    }
                   
                    ,
                    {

                      id:"reqamount",
                      Header: "Actions",
                             
                      accessor: d => {return <div><RequestPayment reqamount={d.reqamount} amount={d.balance} currency={d.coin} mid={this.state.mid}/></div> },




                    }
                    
                    
                  ]}
                  noDataText= "No Balances Found"
                  filterable={false}
                  sortable={false}
                  defaultPageSize={40}
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
    </Col>
          </Row>
        </Grid>
      </div>









    );
  }
}

export default Payments;