import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from "react-select";
import { userService } from 'helpers/user.service.jsx';
const Config = require('config');


class Paynow extends Component {
  constructor(props) {
    super(props);

    this.vForm = this.refs.vForm;
    this.state = {
      loading: true,
      alert: null,
      show: false,
      merchant_id:null,
 description:"",
 widget_url:"",
 widget_id:null,
 widget_email:"",
 currency:'USD',
 descriptionError: null,
 widget_urlError: null,
 widgetname:Config.WidgetName,
 widget_emailError: null,



      
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleValidate = this.handleValidate.bind(this);

 
  }
  componentDidMount(){

    this.getwidgetinfo();


  }

  getwidgetinfo(){

    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);
      var auid=user.data.user_id;
    const requestOptions = {
      headers: { 'access-token': user.token,'mid':user.data.merchant_id,'auid': auid },
    };

    fetch(Config.API+'/api/widget/',requestOptions)
    .then( this.handleResponse)
     .then( widget=> this.setState({description:widget.data.description,
        widget_url:widget.data.widget_url,
        widget_id:widget.data.widget_id,
        merchant_id:widget.data.merchant_id,
        currency:widget.data.currency,
         loading: false}));

  })
  
    }


    updatewidgetinfo(widget_url,widget_id,currency){
   
      userService.getinfo().then((data)=>{

        var user=JSON.parse(data);
      var mid=user.data.merchant_id;
      var auid=user.data.user_id;
      var token=user.token;
var widget_email=''
var description=''
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','access-token': token,'mid':user.data.merchant_id,'auid': auid},
         body: JSON.stringify({ description,widget_url,widget_email,widget_id,currency})
      };
          
    
  return fetch(Config.API+`/api/widget/update`,requestOptions)
     .then( this.handleResponse)
     .then(this.props.handleClick("tr","success","Widget info has been updated",<span data-notify='icon' className='pe-7s-check' />))
    })
    
  }


  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{
          if(data.status=='error'){ 
          userService.logout()
         window.location.reload(true);
            return 'error'
           
          }

        

        }
       

        return data;
    });
}


    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
   
  }

  handleCurrencySelectChange = (data)=> {
    if(data!==null){
    this.setState({ currency:data.value});}
   
  };
  

  
  handleValidate(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    
    

      if(this.state.widget_url!=='')
    
      { if(!this.state.widget_url.match(/^(http|https):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,;]+([\-\.]{1}[-a-zA-Z0-9+&@#/%?=~_|]+)*\.[a-zA-Z]{2,20}(:[0-9]{1,5})?(\/.*)?$/)){this.setState({
        widget_urlError: (
      <small className="text-danger">Please enter a valid URL</small>
      )
      })}else{this.setState({  widget_urlError: null })}
     
    }else{this.setState({  widget_urlError: null })}
  
    
          


  }

  handleSubmit(e) {
    e.preventDefault();
    const { widget_url,widget_id,currency} = this.state;
   if(  this.state.widget_urlError  === null ){
   
  this.updatewidgetinfo(widget_url,widget_id,currency)}

   }
  render() {
    const { currency,description,widget_url,widget_email,loading,widget_id,widgetname} = this.state;
    var CurrencyOptions = [{ value: 'USD', label: 'USD'},{ value: 'CAD', label: 'CAD'},{ value: 'EUR', label: 'EUR'},{ value: 'GBP', label: 'GBP'}];

    if (loading) return <Loader />
   return (   <div className="main-content">
                      
        <Grid fluid>
       
          <Row>
          <Col lg={12}>  <h3>Widget Settings</h3> </Col>
       <Breadcrumb>
            <Breadcrumb.Item href="/#/settings">Settings</Breadcrumb.Item>
            <Breadcrumb.Item active>Pay Now Widget</Breadcrumb.Item>
          </Breadcrumb>
                    <Col md={12}> 
              <Form horizontal onSubmit={this.handleSubmit}>
                <Card
                
                  content={
                    <div>
                     
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       Webhook URL (IPN) <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
        Notifications will be sent to the provided url.
        This field is optional
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="widget_url"
                          value={widget_url}
                          placeholder="https://yourwebhookurl.com"
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.widget_urlError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                Default Currency
                        </Col>
                        <Col sm={6}>
                          
<Select
                          isClearable={false}
                      
                          name="currency"
                          value={currency}
                          options={CurrencyOptions}
                          onChange={this.handleCurrencySelectChange}
                        />
                


                        </Col>
                      </FormGroup>


              

                   
                    
                    </div>
                  }
      
                  legend={
                    <Button
                      fill
                      bsStyle="info"
                      type="submit"
                      onClick={this.handleValidate}
                    >
                     Update
                    </Button>
                  }
                />
              </Form>
            </Col>
            <Col md={12}><div className="card"><div className="header"><h4>Widget Code</h4></div>
            <div className="content"><p><ol className="lgrey"><li>Copy the code below and place it on your site where you want the button to appear.</li><li>Pass your unique reference/ invoice number to the ref_id attribute</li>
            <li>Pass the payment fiat amount to the amount attribute (ie 1.00)</li>
            <li>Pass the fiat currency to the currency attribute (USD,CAD,EUR,GBP)</li></ol><br/> </p> <code className="code">
           {'<div '+widgetname+'="Paywidget" '+widgetname+'_id="'+widget_id+'" amount="1.00" currency="'+this.state.currency+'" ref_id="YourInvoiceId" class="'+widgetname+'paybtn"></div><script src="'+Config.WidgetUrl+'"></script>'}
             </code></div></div></Col> 

                 
                  </Row>
               
        </Grid>
      </div>
    );
  }
}

export default Paynow ;
