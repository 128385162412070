import React, { Component } from "react";
import {Breadcrumb, Grid, Col, Row } from "react-bootstrap";
import { ExportToCsv } from 'export-to-csv';
import ReactTable from "react-table-6";
import Moment from 'react-moment';
import StatsCard from "components/Card/StatsCard.jsx";
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import IconImage from "components/Icon/icon.jsx";
import { userService } from 'helpers/user.service.jsx';
const Config = require('config');


class POS extends Component {




    constructor(props){
        super(props);


        
                this.state = {
                  loading: true,
                paymentsdatalist:[],
                owed:"",
                paid:"",
                kyc:false
                  
                }
               
              //  console.log('this.props', this.props)
            }
    

            
componentWillMount(){

    this.gePOS();
    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);

    if (user.data.kyc=='Verified'){this.setState({kyc:true})}else{
this.setState({kyc:false})}
    })
  }
  
  gePOS(){

    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);
    var mid=user.data.merchant_id;
    var token=user.token;
    var auid=user.data.user_id;
    const requestOptions = {
      headers: {'access-token': token,'mid':user.data.merchant_id,'auid': auid},
   };
      


    fetch(Config.API+'/api/poslist',requestOptions)
    .then( this.handleResponse)
    .then( paymentdata => this.setState({ paymentsdatalist:paymentdata.data, loading: false}))
   
  })
  }




  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{
//console.log(data)
          if(data.status=='error'){
            userService.logout()
            
               return 'error'

          }
        

        }
       

        return data;
    });
}

  


  

  render() {
   
    const {kyc} = this.state; 
    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      return (
          row[id] !== undefined ?
          isNaN(row[id])
            ?  String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            : String(row[id]).startsWith(filter.value)
          :
              true
      );
    }
    
    if (this.state.loading) return <Loader />
    return (
         <div className="main-content">
          
        <Grid fluid>
      
          <Row>
          <Col lg={12}>  <h3>POS Transactions</h3> </Col>
          <Breadcrumb>
            <Breadcrumb.Item href="/#/billing">Billing</Breadcrumb.Item>
            <Breadcrumb.Item active>POS Transactions</Breadcrumb.Item>
          </Breadcrumb>

<Col lg={12}>

          <div className="card">
        
             
<div className="content">

                      <ReactTable
                  
                  data={this.state.paymentsdatalist}
                  filterable
                  defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row) }
                  columns={[
                    {
                      Header: "Date",
                      accessor:"invoice_date",
                      filterable: false,
                      Cell: row => {
                        return <Moment format="MM/D/YYYY">{row.original.invoice_date}</Moment>;
                      },
                    },


                    {
                     
                      Header: "Currency",
              


                      accessor: "currency",
                     Cell: row => {  if (row.value) {return <div alt={row.value}> <IconImage  coin={row.value} />{row.value}</div>}else{return <div></div>}},
                  
                    
                      
                    

                    
                    },
                    {
                      Header: "Amount",
                      accessor:"total_amount",
 
                      Cell: row => <div>{(row.original.total_amount).toFixed(2)}</div>
                    },
                     
                 
                   
                   
                    {
                      Header: "Transaction Id",
                      accessor:"ref_id"    },
                      {
                        Header: "Status",
                        accessor:"payment_status",
                        filterable: false,
                      Cell: row => (row.original.payment_status=='Outstanding'?<div>Pending</div>:<div>{row.original.payment_status}</div>)
                      },

                      {
                        Header: "Actions",
                        width: 200,
                        sortable: false,
                        filterable: false,
                        Cell: row => (
                          <div className="actions-right">
                              <Button                       
    onClick={() => window.location.hash = "#/billing/pos/"+row.original.invoice_id}
bsStyle="info"
simple
icon
><i className="far fa-eye"></i> View</Button>
                          </div>
  
  
  
                      )}
                    
                    
                  ]}
                  defaultPageSize={10}
          
                  showPaginationBottom={true}
                  className="-striped -highlight"
                  noDataText= "No Transactions Found"
                />
              

          </div>
          </div></Col>
          </Row>
        </Grid>
      </div>









    );
  }
}

export default POS;