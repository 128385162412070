import React, { Component } from "react";

const path = require('path')
const Config = require('config');
var ps;
export class IconImage extends Component {


  constructor(props){
    super(props);
    this.state = {
        iconData:[],
        coin:this.props.coin,
        iconurl:''
    }
    
  }
  
 

 componentDidMount(){
    var iconData=JSON.parse(localStorage.getItem('assets'));
    this.setState({iconData:iconData},()=>{
      //console.log(this.props.coin)
      this.geticon(this.props.coin)
  //  console.log(JSON.stringify(this.state.iconData))
    })
      }

     componentWillReceiveProps(nextProps) {
        this.setState({coin:nextProps.coin},()=>{
          this.geticon(this.props.coin)
      //  console.log(JSON.stringify(this.state.iconData))
        });
    }
    
 
 

      geticon(coin) {

        if(coin=='USD' || coin=='CAD' || coin=='EUR'  || coin=='GBP'){
          let coinlower=coin.toLowerCase();
          let icons = JSON.stringify(this.state.iconData.data);

          let iconurl = JSON.parse(icons).filter(e => e.asset_id == 'BTC').map(assetdata =>  assetdata.icon_url )
        let url= path.dirname(iconurl)
          this.setState({iconurl: url+'/'+coinlower+'.png'})}else{
        let icons = JSON.stringify(this.state.iconData.data);

       let iconurl = JSON.parse(icons).filter(e => e.asset_id == coin).map(assetdata =>  assetdata.icon_url ) 
    
        this.setState({iconurl: iconurl});}
      }


  
  render() {
    const {iconurl} = this.state;

    return (
     <img className="balance_icon" src={iconurl} />

     
        
      
        
    )
  }
}

export default IconImage;
