import React, { Component } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import Radio from "components/CustomRadio/CustomRadio.jsx";
import Loader from "components/Loader/loader.jsx";
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import {     Breadcrumb,  Grid,
  Row,
  Col,
FormControl,
Form,
FormGroup,
ControlLabel,
  Modal,
  } from "react-bootstrap";
  import { userService } from 'helpers/user.service.jsx';
const Config = require('config');
var ps;
export class RequestPayment extends Component {


  constructor(props){
    super(props);
    this.state = {
      showModal:false,
      showCryptoModal:false,
settled_fees:0,
isSubmitted:false,
settled_payments:0,
owedAmount:this.props.amount,
paidAmount:0,
currency:this.props.currency,
mid:this.props.mid,
requestedAmount:this.props.reqamount,
Amount:this.props.amount,
_notificationSystem: null,
transaction_ids:[],
alert: null,
usdt_tron:0,
usdt_erc20:0,
usdt_tron_amount:0,
usdt_erc20_amount:0,
      show: false,
      settleType:"Wire"
    }
    this.hideAlert = this.hideAlert.bind(this);
  }
    hideAlert() {
    this.setState({
      alert: null
    });
  }
  cryptoRequest() {
  if(this.state.owedAmount>0)
  {this.setState({
    showCryptoModal:true
    })
  }else{
    this.setState({
      alert: (
        <SweetAlert
        warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Sorry"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
         
          confirmBtnBsStyle="default"
        >You don't have a balance to settle</SweetAlert>
      )
    })

  }
  }


  fiatRequest() {
    if(this.state.owedAmount>=100)
    {
     
    this.setState({
    showModal:true
    })
   }else{
      this.setState({
        alert: (
          <SweetAlert
          warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Sorry"
            onConfirm={() => this.hideAlert()}
          
            confirmBtnBsStyle="btn"
            confirmBtnText="Close"
          >You must have a balance of at least {this.props.currency=='USD' || this.props.currency=='CAD'
          ? '$'
          : this.props.currency=='EUR'
          ? '€'
        : this.props.currency=='GBP'
        ? '£'
      :''}100.00 to settle</SweetAlert>
        )
      })
  
    }
    }
  fiatRequest2() {
    if(this.state.owedAmount>=100)
    {
     if(this.state.currency=='USD'){
      this.getcryptoprice('usd','usdt').then((price)=>{

        this.setState({usdt_erc20:price},()=>{

          this.getcryptoprice('usd','usdt-trc').then((price)=>{

            this.setState({usdt_tron:price},()=>{
          this.setState({
            showModal:true
            })

          })

        })
      })
    })


     }else{
    this.setState({
    showModal:true
    })
  }
   }else{
      this.setState({
        alert: (
          <SweetAlert
          warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Sorry"
            onConfirm={() => this.hideAlert()}
          
            confirmBtnBsStyle="btn"
            confirmBtnText="Close"
          >You must have a balance of at least {this.props.currency=='USD' || this.props.currency=='CAD'
          ? '$'
          : this.props.currency=='EUR'
          ? '€'
        : this.props.currency=='GBP'
        ? '£'
      :''}100.00 to settle</SweetAlert>
        )
      })
  
    }
    }
 

 componentDidMount(){
  this.setState({ _notificationSystem: this.refs.notificationSystem },()=>{

 //   console.log(this.state.currency)
  //  console.log(this.state.mid)
   // console.log(this.state.owedAmount)
   // console.log(this.state.requestedAmount)
  });
      }


      componentWillReceiveProps(nextProps) {
        this.setState({currency:nextProps.coin,owedAmount:nextProps.amount,requestedAmount:nextProps.reqamount,mid:nextProps.mid,},()=>{
         
         // console.log(this.state.currency)
        //  console.log(this.state.mid)
          //console.log(this.state.owedAmount)
         // console.log(this.state.requestedAmount)
      //  console.log(JSON.stringify(this.state.iconData))
        });
    }



  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            userService.logout()
            
               return 'error'

          }
        

        }
       

        return data;
    });
}

  



getcryptoprice(to,from){

  return new Promise( (resolve)=> {
    userService.getinfo().then((data)=>{
    var user=JSON.parse(data);
  var mid=user.data.merchant_id;
var token=user.token;
var auid=user.data.user_id;


  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':user.data.merchant_id,'auid': auid},
     body: JSON.stringify({to,from})
  };
      

return fetch(Config.API+`/api/cryptoprice`,requestOptions)
.then( this.handleResponse)
.then( price=> resolve(price.data))
  })
})

}


createRequest(){

  userService.getinfo().then((data)=>{

    var user=JSON.parse(data);
  var mid=user.data.merchant_id;
var token=user.token;

  var amount=this.state.owedAmount


  var type=this.state.settleType
  var auid=user.data.user_id;
var currency=this.state.currency

  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':user.data.merchant_id,'auid': auid},
     body: JSON.stringify({currency,type})
  };
      

return fetch(Config.API+`/api/payments/request`,requestOptions)
.then( this.handleResponse)
.then(
   this.setState({requestedAmount:amount},function(){
  this.handleNotificationClick("tr","success","Payment has successfully been requested ",<span data-notify='icon' className='pe-7s-check' />)
 })
 )

 

})
}



handleSubmit() {

this.setState({showModal:false,showCryptoModal:false},function(){
 this.createRequest()
})
 



  }


  
  



handleNotificationClick(position,level,message,title) {
  

  this.state._notificationSystem.addNotification({
    title: title,
    message: (
     message
    ),
    level: level,
    position: position,
    autoDismiss: 3
  });
}

handleRadio = event => {
  const target = event.target;
  this.setState({
    [target.name]: target.value
  },function(){});
};

  render() {
const{isSubmitted}=this.state
     if(this.state.paidAmount==null){
    this.setState({paidAmount:'0'})}
    


    if(this.state.owedAmount==null){
    this.setState({owedAmount:'0'})}
   

    return (
      <div>
          {this.state.alert}
      <NotificationSystem ref="notificationSystem" style={style} />
      <div >
       

  
   
          { (this.props.currency=='USD' || this.props.currency=='CAD' || this.props.currency=='EUR' || this.props.currency=='GBP')
           ? this.state.requestedAmount==0
         ?<Button  bsStyle="default" onClick={()=>this.fiatRequest()}>
                       Send Balance
                        <span className="btn-label btn-label-right">
                        <i className="fas fa-money-bill"></i>
                        </span>
                      </Button>
                      :<div className="messagesuccess">A payment for {this.props.currency=='USD' || this.props.currency=='CAD'
                      ? '$'
                      : this.props.currency=='EUR'
                      ? '€'
                    : this.props.currency=='GBP'
                    ? '£'
                  :''}{this.state.requestedAmount} {this.state.currency} is being processed</div>
                    : (this.props.currency=='BTC' || this.props.currency=='BCH' || this.props.currency=='LTC' || this.props.currency=='ETH' || this.props.currency=='USDT' || this.props.currency=='DOGE' || this.props.currency=='USDC' || this.props.currency=='XRP' || this.props.currency=='SOL' || this.props.currency=='DASH' || this.props.currency=='USDT_TRON' || this.props.currency=='BNB' || this.props.currency=='SHIB' || this.props.currency=='MLP' || this.props.currency=='MATIC_POLY' || this.props.currency=='ADA' || this.props.currency=='AVAX'|| this.props.currency=='TSUKA' ) 
                    ?this.state.requestedAmount==0
                    ?<Button  bsStyle="default" onClick={()=>this.cryptoRequest()}>
                                   Send Balance
                                   <span className="btn-label btn-label-right">
                                  
                                   </span>
                                 </Button>
                                 :<div className="messagesuccess">A payment for {this.props.currency=='USD' || this.props.currency=='CAD'
                                 ? '$'
                                 : this.props.currency=='EUR'
                                 ? '€'
                               : this.props.currency=='GBP'
                               ? '£'
                             :''}{this.state.requestedAmount} {this.state.currency} is being processed</div>
                   : <div></div> }
       
       
      </div>
      <Modal
        backdrop='static'
        keyboard={false}
                      show={this.state.showModal}
                      onHide={() => this.setState({ showModal: false })}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Send Balance</Modal.Title>
                      </Modal.Header> 
                      <Modal.Body>
                      <Grid fluid>
                      <Row>
         
       
         <Col md={12}> 
         
         <Form horizontal onSubmit={this.handleSubmit}>

<FormGroup controlId="formHorizontalRequiredText">
<Col  sm={12}>
Please make sure your {this.state.currency} banking / address info is correct in your settings. </Col>
  <Col componentClass={ControlLabel} sm={5} smOffset={0}>
Select your payment type
  </Col>
  
                <Col sm={7}> 
                {this.state.currency=='USD' 
                ?<div><Radio number="1"
                            option="Wire"
                            name="settleType"
                            onChange={this.handleRadio}
                            checked={this.state.settleType === "Wire"}
                            label="Wire Transfer ($15.00 Fee)"
                          />
                        
                           <Radio
                            number="2"
                            option="USDT"
                            name="settleType"
                            onChange={this.handleRadio}
                            checked={this.state.settleType === "USDT"}
                            label={"USDT ERC20 ( You will recieve "+this.state.owedAmount+" - 10.00 Fee )"}
                          />

                         {/*    <Radio
                            number="3"
                            option="USDT_TRON"
                            name="settleType"
                            onChange={this.handleRadio}
                            checked={this.state.settleType === "USDT_TRON"}
                            label={"USDT TRON ("+this.state.owedAmount+" - 3.00 Fee)"}
                          /> */}
                          
                          </div>
                          :this.state.currency=='CAD'
                          ?<div>
                          <Radio number="1"
                                                      option="Wire"
                                                      name="settleType"
                                                      onChange={this.handleRadio}
                                                      checked={this.state.settleType === "Wire"}
                                                      label="Wire Transfer ($15.00 Fee)"
                                                    />
                      {/* <Radio
                                                      number="2"
                                                      option="e-Transfer"
                                                      name="settleType"
                                                      onChange={this.handleRadio}
                                                      checked={this.state.settleType === "e-Transfer"}
                                                      label="e-Transfer (Canadian Accounts Only)"
                                                    /> */}
                                                    
                                                    </div>
                                                    :this.state.currency=='EUR'
                                                    ?<div><Radio
                          number="1"
                          option="Wire"
                          name="settleType"
                          onChange={this.handleRadio}
                          checked={this.state.settleType === "Wire"}
                          label="Wire Transfer (€15.00 Fee)"
                        />
                       </div>
                       :<div><Radio
                       number="1"
                       option="Wire"
                       name="settleType"
                       onChange={this.handleRadio}
                       checked={this.state.settleType === "Wire"}
                       label="Wire Transfer (£15.00 Fee)"
                     />
                    </div>}


                          
  </Col>

</FormGroup></Form>
         
         </Col></Row></Grid></Modal.Body>
         <Modal.Footer>
                        <Button
                          simple
                          onClick={() => this.setState({ showModal: false })}
                        >Cancel
                        </Button>
                        <Button
                        disabled={isSubmitted}
                         simple
                         onClick={() => this.handleSubmit()}
                         
                        >Submit
                        </Button>
                      </Modal.Footer>
                      </Modal>


                      <Modal
        backdrop='static'
        keyboard={false}
                      show={this.state.showCryptoModal}
                      onHide={() => this.setState({ showCryptoModal: false })}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Send Balance</Modal.Title>
                      </Modal.Header> 
                      <Modal.Body>
                      <Grid fluid>
                      <Row>
         
       
         <Col sm={12}> 
         
         <Form horizontal onSubmit={this.handleSubmit}>

<FormGroup controlId="formHorizontalRequiredText">
  <Col sm={12}>
You will receive {this.state.owedAmount} {this.state.currency}  minus the network fees<br/>
Please make sure your {this.state.currency} wallet address is correct in your settings. 
  </Col>
  

</FormGroup></Form>
         
         </Col></Row></Grid></Modal.Body>
         <Modal.Footer>
                        <Button
                          simple
                          onClick={() => this.setState({ showCryptoModal: false })}
                        >Cancel
                        </Button>
                        <Button
                        disabled={isSubmitted}
                         simple
                         onClick={() => this.handleSubmit()}
                         
                        >Submit
                        </Button>
                      </Modal.Footer>
                      </Modal>
      </div>
    );
  }
}

export default RequestPayment;
