import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";
import Select from "react-select";
import "react-select/dist/react-select.css";
import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckboxv2.jsx";
import { userService } from 'helpers/user.service.jsx';
const Config = require('config');


class AccountInfo extends Component {
  constructor(props) {
    super(props);

    this.vForm = this.refs.vForm;
    this.state = {
      loading: true,
      created:false,
      alert: null,
      show: false,
      new:false,
      update:false,
      status:'Disabled',
      statusval:false,
      api_id:"",
      name:"",
      url:"",
      secretkey:"",
      url:"",
      apikey:"",
      urlError: null,
      nameError: null,
      
     
     
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadPage=this.loadPage.bind(this);
    this.handleValidate = this.handleValidate.bind(this);

 
  }

  componentWillMount(){
 this.props.match.params.apiid!='new'
   ? this.setState({api_id:this.props.match.params.apiid},this.
    getapiinfo())
   : this.setState({loading: false,new:true})


  }

    getapiinfo(){

      userService.getinfo().then((data)=>{

        var user=JSON.parse(data);
        var auid=user.data.user_id;
      const requestOptions = {
        headers: { 'access-token': user.token,'mid':user.data.merchant_id,'auid': auid },
      };
  
      fetch(Config.API+'/api/apikey/'+this.props.match.params.apiid,requestOptions)
      .then( this.handleResponse)
       .then( api=> this.setState({name:api.data.name,
          apikey:api.data.key,
          api_id:this.props.match.params.apiid,
          url:api.data.url,status:api.data.status, loading: false},function(){

            if(this.state.status=='Active'){this.setState({statusval:true})}else{this.setState({statusval:false})}
           
          }));
  
       
        })
      }



    updateapiinfo(name,url,api_id,status){
      userService.getinfo().then((data)=>{

        var user=JSON.parse(data);
        var auid=user.data.user_id;
     var merchant_id= user.data.merchant_id
var token=user.token;
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':user.data.merchant_id,'auid': auid},
         body: JSON.stringify({ name,url,api_id,status})
      };
          
    
  return fetch(Config.API+`/api/apikey/update`,requestOptions)
     .then( this.handleResponse)
     .then(this.props.handleClick("tr","success","Api Key info has been updated",<span data-notify='icon' className='pe-7s-check' />))
    
    })
  }


  handlecreateapi(nameg){
    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);
    var mid=user.data.merchant_id;
    var name=nameg;
    var auid=user.data.user_id;
  var token=user.token;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':user.data.merchant_id,'auid': auid},
       body: JSON.stringify({name})
    };
        
  
  return fetch(Config.API+`/api/apikeys/create`,requestOptions)
   .then( this.handleResponse)
   .then( api => this.setState({name:api.data.name,apikey:api.data.key,api_id:api.data.api_id,secretkey:api.data.secretkey,created:true,status:'Active',statusval:true}))
   .then(this.props.handleClick("tr","success","Api Key has been created",<span data-notify='icon' className='pe-7s-check' />))
  })
}





  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{
        
          if(data.status=='error'){
            userService.logout()
            
               return 'error'
          }
        

        }
       

        return data;
    });
}


    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value },()=>{
});
   
  }

  handleChangeCheck(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value },()=>{


      this.state.statusval==true
      ? this.setState({statusval:false,status:'Disabled'})
   : this.setState({statusval:true,status:'Active'})});
 
}

validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }


loadPage(){window.location.hash = "#/settings/api"


}

handleValidate(e) {

   this.state.name.length < 8
  ? this.setState({
    nameError: (
  <small className="text-danger">Api Key Name must be at least 8 characters long</small>
  )
  })

     : !this.state.name.match(/^[A-Za-z0-9 ]+$/)
     ? this.setState({
      nameError: (
         <small className="text-danger">Api Key Name must not contain any special characters</small>
       )
         })
    : this.setState({  nameError: null });

   if(this.state.url!=='')
    
    { if(!this.state.url.match(/^(http|https):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,;]+([\-\.]{1}[-a-zA-Z0-9+&@#/%?=~_|]+)*\.[a-zA-Z]{2,20}(:[0-9]{1,5})?(\/.*)?$/)){this.setState({
      urlError: (
    <small className="text-danger">Please enter a valid URL</small>
    )
    })}else{this.setState({  urlError: null })}
   
  }else{this.setState({  urlError: null })}





  }

  async  handleSubmit(e) {
    e.preventDefault();

 await this.handleValidate();
    const {name,url,api_id,status} = this.state; 
    if(this.state.new===true){
   if( this.state.nameError === null ){



  
   
  this.handlecreateapi(name);
  
   }}else{
    if( this.state.nameError === null && this.state.urlError === null ){


      this.updateapiinfo(name,url,api_id,status)
   }
    }
  }



  render() {
    const { name,loading,secretkey,url,statusval} = this.state;
   
    
   
    if (loading) return <Loader />
     return (
      <div className="main-content">


 <Grid fluid>

       
          <Row>
         
          <Col lg={12}>  <h3>API Key Info</h3> </Col> <Breadcrumb>
            <Breadcrumb.Item href="/#/settings">Settings</Breadcrumb.Item>
            <Breadcrumb.Item href="/#/settings/api">API Keys</Breadcrumb.Item>
            <Breadcrumb.Item active>API Key info</Breadcrumb.Item>
          </Breadcrumb>
                    <Col md={12}> {this.state.created===true
             ?<Form horizontal >

<Card
             
                  content={
                    <div>
 <FormGroup ><Col componentClass={ControlLabel} sm={12}>
                    <div className="apikeyinfo"><h2>API Key has been Created</h2>
Please copy this Secret key and save it somewhere safe.<br/><div className="warning">For security reasons, we cannot show it to you again.</div>

                  <div className="secretkey">{secretkey}</div></div> 
</Col></FormGroup>

</div> }    legend={
                    <Button
                      fill
                      bsStyle="info"
                      
                    
                      onClick={this.loadPage}
                    >
                      Continue
                    </Button>
                    
                  }
                />
                
                </Form>



             : 
            this.state.new===true
           ?<Form horizontal onSubmit={this.handleSubmit}>
                <Card
                  title="API Key Info"
                  content={
                    <div>
 <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       API Key Name
                        </Col>

                        <Col sm={6}>
                        {this.state.new===true
                        ?  <FormControl
                            type="text"
                            name="name"
                          value={name}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
:<div  className="textdisplay">{this.state.name}</div>  }
                           {this.state.nameError}
                        </Col>


                      </FormGroup>



                    
                    </div>
                  }
      
                  legend={
                    <Button
                      fill
                      bsStyle="info"
                      type="submit"
                    
                      onClick={this.handleValidate}
                    >
                    Generate Key
                    </Button>
                    
                  }
                />
              </Form>
              
            :<Form horizontal onSubmit={this.handleSubmit}>
            <Card
              title="API Key Info"
              content={
                <div>
<FormGroup controlId="formHorizontalRequiredText">
                    <Col componentClass={ControlLabel} sm={4} >
                  API Key  Name
                    </Col>

                    <Col sm={6}>
                     <FormControl
                        type="text"
                        name="name"
                      value={name}
                        onBlur={this.handleValidate}
                        onChange={this.handleChange}
                      />

                       {this.state.nameError}
                    </Col>  </FormGroup>
                    <FormGroup controlId="formHorizontalRequiredText">
                    <Col componentClass={ControlLabel} sm={4} >
                    <i className="fal fa-key"></i> Public key
                    </Col>

                    <Col sm={6}>
                    <div  className="textdisplay">{this.state.apikey}</div>
                     
                    </Col>
                    </FormGroup>
                    <FormGroup controlId="formHorizontalRequiredText">
                    <Col componentClass={ControlLabel} sm={4} >
                    <i className="fal fa-key"></i> Secret key <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
        For security reasons we do not allow the secret key value to be displayed.
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                    </Col>

                    <Col sm={6}>
                    <div  className="textdisplay">*********************</div>
                     
                    </Col>

                  </FormGroup>
                  <FormGroup controlId="formHorizontalRequiredText">
                    <Col componentClass={ControlLabel} sm={4} >
                    Webhook URL (IPN) <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
        Notifications will be sent to the provided url.
        This field is optional
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                    </Col>

                    <Col sm={6}>
                     <FormControl
                        type="text"
                        name="url"
                        placeholder="https://yourwebhookurl.com"
                      value={url}
                        onBlur={this.handleValidate}
                        onChange={this.handleChange}
                      />

                       {this.state.urlError}
                    </Col>  </FormGroup>


   <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                Active
                        </Col>
                        <Col sm={6}> 
                        <Checkbox
                        isChecked={statusval}
                        id="1"
                        onBlur={this.handleValidate}
                        onChange={this.handleChangeCheck}
                      />
                 
           
                        </Col>
                      </FormGroup>
                
                </div>
              }
  
              legend={
                <Button
                  fill
                  bsStyle="info"
                  type="submit"
                
                  onClick={this.handleValidate}
                >
                  Update Key
                </Button>
                
              }
            />
          </Form>
            }
            </Col>

                 
                  </Row>
               
        </Grid>
      </div>
    );
  }
}

export default AccountInfo ;
