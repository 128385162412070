import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";



import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import "react-select/dist/react-select.css";
import { userService } from 'helpers/user.service.jsx';
const Config = require('config');



class CustomerInfo extends Component {
  constructor(props) {
    super(props);

    this.vForm = this.refs.vForm;
    this.state = {
      total:0,
      error:false,
      loading: true,
      alert: null,
 
  
      newcustomer:false,
    
      email:'',
      lastname:'',
      firstname:'',
      emailError:null,

phone:"",
      displayname:"",
      address:"",
      company:"",
      city:"",
      prov_state:"",
      postal_zip:"",
      country:"",
company:"",
     displaynameError: null,
 
  clid:null

     
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleValidate = this.handleValidate.bind(this);
 

 
  }

  componentWillMount(){
 this.props.match.params.clid!='new'
   ? this.setState({clid:this.props.match.params.clid},this.getcustomerinfo)
   : this.setState({loading: false,newcustomer:true})
  

  }




  getcustomerinfo(){

    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);
      var auid=user.data.user_id;
    const requestOptions = {
      headers: { 'Content-Type': 'application/json' ,'access-token': user.token,'mid':user.data.merchant_id,'auid': auid },
    };

    fetch(Config.API+'/api/customer/'+this.props.match.params.clid,requestOptions)
    .then( this.handleResponse)
     .then( customer=> this.setState({
        email:customer.data.email,
        firstname:customer.data.firstname,
        lastname:customer.data.lastname,
        phone:customer.data.phone,
        country:customer.data.country,
        prov_state:customer.data.prov_state,
        postal_zip:customer.data. postal_zip,
        displayname:customer.data.display_name,
        city:customer.data.city,
        company:customer.data.company,
        address:customer.data.address,


        customer_id:this.props.match.params.clid, loading: false}));

  })
  
    }





 



    updatecustomerinfo(email,firstname,lastname,phone,country,prov_state,postal_zip,displayname,city,company,address,customer_id){
  
      userService.getinfo().then((data)=>{

        var user=JSON.parse(data);
      var mid=user.data.merchant_id;
      var auid=user.data.user_id;
var token=user.token;
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':user.data.merchant_id,'auid': auid},
         body: JSON.stringify({email,firstname,lastname,phone,country,prov_state,postal_zip,displayname,city,company,address,customer_id})
      };
         
    
  return fetch(Config.API+'/api/customer/update',requestOptions)
     .then( this.handleResponse)
     .then(this.props.handleClick("tr","success","User has been updated",<span data-notify='icon' className='pe-7s-check' />))
    
    })
  }


  savecustomerinfo(email,firstname,lastname,phone,country,prov_state,postal_zip,displayname,city,company,address){
    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);
    var mid=user.data.merchant_id;
var token=user.token;
var auid=user.data.user_id;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':user.data.merchant_id,'auid': auid},
       body: JSON.stringify({email,firstname,lastname,phone,country,prov_state,postal_zip,displayname,city,company,address})
    };
        
  
return fetch(Config.API+`/api/customer/create`,requestOptions)
   .then( this.handleResponse)
   .then( customer => this.setState({newcustomer:false, clid:customer.data.customer_id,update:true}))
   .then(this.props.handleClick("tr","success","User has been created",<span data-notify='icon' className='pe-7s-check' />))
  
  })
}


  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{
          console.log(data.status)
          if(data.status=='error'){
            userService.logout()
            
               return 'error'
          }
        

        }
       

        return data;
    });
}

    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });

      if(this.state.newcustomer===true){


        if(name==='company' && (this.state.firstname==='' || this.state.lastname==='')){

          this.setState({displayname:value})

        }

      if((name==='firstname')){

    
        this.setState({displayname:value+" "+this.state.lastname})
  
        
      }

      if((name==='lastname')){

    
        this.setState({displayname:this.state.firstname+" "+value})
  
        
      }
    
    
    }
   
  }



  handleCheckClick= async () => {
    await this.setState({ is_checked: !this.state.is_checked });
    await   this.state.is_checked==true? this.setState({active:1}):this.setState({active:0})
 
  }


   
  handleValidate(e) {


    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    re.test(this.state.email) === false
      ? this.setState({
       emailError: (
            <small className="text-danger">
              This must be a valid email address.
            </small>
          )
        })
      : this.setState({ emailError: null });



             
              this.state.displayname==""
              ? this.setState({
                displaynameError: (
                   <small className="text-danger">this field is required</small>
                 )
                   })
                  : this.setState({ displaynameError: null });


        
               

                    return
}

  handleSubmit(e) {
    e.preventDefault();
  
    const {email,firstname,lastname,phone,country,prov_state,postal_zip,displayname,city,company,address,clid} = this.state;
 
    if(this.state.newcustomer){if(this.state.displaynameError === null && this.state.emailError === null){
      this.savecustomerinfo(email,firstname,lastname,phone,country,prov_state,postal_zip,displayname,city,company,address)
    
    }}else{
        
 if(this.state.displaynameError === null && this.state.emailError === null ){
  this.updatecustomerinfo(email,firstname,lastname,phone,country,prov_state,postal_zip,displayname,city,company,address,clid)}}

   }

   render() {
  
    
    
    const {email,newcustomer,firstname,lastname,phone,country,prov_state,postal_zip,displayname,city,company,address} = this.state; 
   
if (this.state.loading) return <Loader />
      return (
      <div className="main-content">
                      
        <Grid fluid>
    
          <Row>
          <Col lg={12}>  <h3>Customer</h3> </Col>
           <Breadcrumb>
            <Breadcrumb.Item href="/#/billing">Billing</Breadcrumb.Item>
            <Breadcrumb.Item  href="/#/billing/customers">Customers</Breadcrumb.Item>
            <Breadcrumb.Item active>Customer Details</Breadcrumb.Item>
          </Breadcrumb>
                    <Col md={12}> 
              <Form horizontal onSubmit={this.handleSubmit}>
                <Card
                 
                  content={
                    <div>
              




            


        <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         First Name
                        </Col>
                        <Col sm={6}>
                        <FormControl
                            type="text"
                            name="firstname"
                          value={firstname}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                        
                    

                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Last Name
                        </Col>
                        <Col  sm={6}>
                        <FormControl
                            type="text"
                            name="lastname"
                          value={lastname}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                       
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       Company Name
                        </Col>
                        <Col  sm={6}>
                        <FormControl
                            type="text"
                            name="company"
                          value={company}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
               
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       Display Name
                        </Col>
                        <Col  sm={6}>
                        <FormControl
                            type="text"
                            name="displayname"
                          value={displayname}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.displaynameError}
                        </Col>
                      </FormGroup>



              <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Email
                        </Col>
                        <Col  sm={6}>
           
                         <FormControl
                            type="text"
                            name="email"
                          value={email}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                     

                    
                         {this.state.emailError}
                        </Col>
                        </FormGroup>



                        <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Phone Number
                        </Col>
                        <Col  sm={6}>
                       <FormControl
                            type="text"
                            name="phone"
                          value={phone}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                        

                    
                        
                        </Col>
                        </FormGroup>

                  
                        <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Address
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="address"
                          value={address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                        
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        City
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="city"
                          value={city}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                       
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       Province/State
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="prov_state"
                          value={prov_state}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                        
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                     Country
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="country"
                          value={country}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                       
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Postal/Zip Code
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="postal_zip"
                          value={postal_zip}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                       
                        </Col>
                      </FormGroup>


                 
                    </div>
                  }
                  legend={
                    <Button
                      fill
                      bsStyle="info"
                      type="submit"
                    
                      onClick={this.handleValidate}
                    >
                      Save
                    </Button>
                    
                  }
      
                />
              </Form>
            </Col>
                 
                  </Row>
               
        </Grid>
      </div>
    );
  }
}

export default CustomerInfo ;
