import React, { Component } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import Radio from "components/CustomRadio/CustomRadio.jsx";
import Loader from "components/Loader/loader.jsx";
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import {     Breadcrumb,  Grid,
  Row,
  Col,
FormControl,
Form,
FormGroup,
ControlLabel,
  Modal,
  } from "react-bootstrap";
  import { userService } from 'helpers/user.service.jsx';
const Config = require('config');
var ps;
export class PaymentsCard extends Component {


  constructor(props){
    super(props);
    this.state = {
      showModal:false,
      showCryptoModal:false,
settled_fees:0,
isSubmitted:false,
settled_payments:0,
owedAmount:0,
paidAmount:0,
currency:this.props.currency,
mid:this.props.mid,
requestedAmount:0,
_notificationSystem: null,
transaction_ids:[],
alert: null,
      show: false,
      settleType:"Wire"
    }

  }





 

 componentDidMount(){
    this.setState({ _notificationSystem: this.refs.notificationSystem });
       this.getPaymentsTotals();

    
      }


  getPaymentsTotals(){

    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);

    var token=user.token;
    var auid=user.data.user_id;
    const requestOptions = {
      headers: {'access-token': token,'mid':user.data.merchant_id,'auid': auid},
   };
      




    fetch(Config.API+'/api/payments/totals/'+this.state.currency,requestOptions)
    .then( this.handleResponse)
    .then( totaldata => this.setState({ paidAmount:totaldata.data.total_amount_paid,owedAmount:totaldata.data.total_payment_owed,settled_payments:totaldata.data.total_settled_payments,settled_fees:totaldata.data.total_settled_fees},function(){
      if  (this.props.currency=='USD' || this.props.currency=='CAD' || this.props.currency=='EUR' || this.props.currency=='GBP'){
  
        
      
        if (this.state.owedAmount==null){this.setState({owedAmount:0})}else{this.setState({owedAmount:this.state.owedAmount.toFixed(2)})}

        if (this.state.settled_fees==null){this.setState({settled_fees:0})}else{this.setState({settled_fees:this.state.settled_fees.toFixed(2)})}

        if (this.state.settled_payments==null){this.setState({settled_payments:0})}else{this.setState({settled_payments:this.state.settled_payments.toFixed(2)})}
      
      }else{
        
        if (this.state.owedAmount==null){this.setState({owedAmount:0})}else{this.setState({owedAmount:this.state.owedAmount.toFixed(8)})}
      if (this.state.settled_fees==null){this.setState({settled_fees:0})}else{this.setState({settled_fees:this.state.settled_fees.toFixed(8)})}

      if (this.state.settled_payments==null){this.setState({settled_payments:0})}else{this.setState({settled_payments:this.state.settled_payments.toFixed(8)})}

      }
    }));
   
  })

  }


  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            userService.logout()
            
               return 'error'

          }
        

        }
       

        return data;
    });
}

  





  
  




  render() {
const{isSubmitted}=this.state
     if(this.state.paidAmount==null){
    this.setState({paidAmount:'0'})}
    


    if(this.state.owedAmount==null){
    this.setState({owedAmount:'0'})}
   
    if (this.state.loading) return     <div className="card card-stats">
    <div className="content">
      <div className="row">
        <div className="col-xs-5"><Loader /></div></div></div></div>
    return (
      <div>
          {this.state.alert}
      <NotificationSystem ref="notificationSystem" style={style} />
      <div className="card card-stats">
        <div className="content">
          <div className="row">
            <div className="col-xs-5">
           
              <div className="currency">
                {this.props.currency}
              </div>
            </div>
            <div className="col-xs-7">    
              <div className="numbers">
                <p>Payable Owed to Merchant</p>
{this.props.currency=='USD' || this.props.currency=='CAD'
? '$'
: this.props.currency=='EUR'
? '€'
: this.props.currency=='GBP'
? '£'
: ''}{this.state.owedAmount}

              </div>
             <div className="fees">Fees: {this.props.currency=='USD' || this.props.currency=='CAD'
             ? '$'
             : this.props.currency=='EUR'
             ? '€'
             : this.props.currency=='GBP'
             ? '£'
             : ''} {this.state.settled_fees}</div>


              <div className="numbers">
                <p>Paid to Date Total</p>

                {this.props.currency=='USD' || this.props.currency=='CAD'
? '$'
: this.props.currency=='EUR'
? '€'
: this.props.currency=='GBP'
? '£'
: ''}{this.state.paidAmount}
</div>


            </div>
          </div>
        </div>
        <div className="footer">
          <hr />
          <div className="stats">
   
        
          </div>
        </div>
      </div>
   
      </div>
    );
  }
}

export default PaymentsCard;
