import React, { Component } from "react";
import {  FormControl,FormGroup,
  Form, Modal, Breadcrumb,Grid, Col,ControlLabel, Row } from "react-bootstrap";

import ReactTable from "react-table-6";
import Moment from 'react-moment';

import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { userService } from 'helpers/user.service.jsx';
const Config = require('config');


class Apis extends Component {




    constructor(props){
        super(props);


        
                this.state = {
                  showModal: false,
                  api_id:"",
                  loading: true,
                apikeyslist:[],
                owed:"",
                paid:"",
                  name:"",
                  nameError:null,
                  kyc:false
                }
       
               // console.log('this.props', this.props)
            }
    

            
componentWillMount(){

    this.getAPIs();
    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);

    if (user.data.kyc=='Verified'){this.setState({kyc:true})}else{
this.setState({kyc:false})}
    })
  }

  getAPIs(){

    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);
    var mid=user.data.merchant_id;
    var token=user.token;
    var auid=user.data.user_id;
    const requestOptions = {
      headers: {'access-token': token,'mid':user.data.merchant_id,'auid': auid},
   };
      


    fetch(Config.API+'/api/apikeys/',requestOptions)
    .then( this.handleResponse)
    .then( paymentdata => this.setState({ apikeyslist:paymentdata.data, loading: false}));
  })
  }





  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
 
 
  
}


  
  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            userService.logout()
            
               return 'error'

          }
        

        }
       

        return data;
    });
}

  

newapi(){ window.location.hash = "#/settings/api/new"};

  

  render() {
    const {name,kyc} = this.state;
    if (this.state.loading) return <Loader />
    return (
         <div className="main-content">
          
        <Grid fluid>
       
          <Row>
   
          <Col lg={12}>  <h3>API Keys</h3> </Col> <Breadcrumb>
            <Breadcrumb.Item href="/#/settings">Settings</Breadcrumb.Item>
            <Breadcrumb.Item active>API Keys</Breadcrumb.Item>
          </Breadcrumb>
<Col lg={12}>

          <div className="card">
        
             

          <div className="content">
          {kyc==true?<Button  bsStyle="default"  onClick={() => this.newapi()}>
                      New
                        <span className="btn-label btn-label-right">
                        <i className="fal fa-key" />
                        </span>
                      </Button>
:<div></div>}
                      <ReactTable
                  
                  data={this.state.apikeyslist}
               
                  columns={[
                    {
                      Header: "Name",
                      accessor:"name",
                     
                     
                    },
                   
                    {
                      Header: "Public Key",
                      accessor:"key"         }
                      ,
                      {
                        Header: "Secret Key",
                       
                        Cell: row => (
                          <div ><i className="fal fa-key"></i> *************** </div>)         }
                        
                     
                          ,{
                            Header: "Status",
                            accessor:"status",
                           
                           
                          },{
                        Header: "Actions",
                        width: 200,
                        Cell: row => (
                          <div className="actions-right">
                               <Button
                               
                               onClick={() => window.location.hash = "#/settings/api/"+row.original.api_id}
    bsStyle="info"
    simple
    icon
    ><i className="fal fa-edit"></i> Edit</Button>{" "}
                          </div>



                      )}
                    
                    
                  ]}
                  noDataText= "No API Key Found"
                  showPaginationBottom={true}
                  className="-striped -highlight"
                />
              

          </div>
          </div></Col>
          </Row>
        </Grid>
        <Modal
                      show={this.state.showModal}
                      onHide={() => this.setState({ showModal: false })}
                      shouldCloseOnOverlayClick={false}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Create API key</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      <Grid fluid>
                      <Row>
         
       
         <Col md={12}>    <Form horizontal onSubmit={this.handleSubmit}>

<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={4} smOffset={1}>
API Key Name
  </Col>
  
  <Col sm={7}>
  <FormControl
                            type="text"
                            name="name"
                            
                    
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />

    {this.state.nameError}
  </Col>
</FormGroup>
</Form>
        </Col></Row></Grid>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          simple
                          onClick={() => this.setState({ showModal: false })}
                        >Close
                        </Button>
                        <Button
                         simple
                         onClick={() => this.handleSubmit()}
                         
                        >Create API Key
                        </Button>
                      </Modal.Footer>
                    </Modal>
      </div>









    );
  }
}

export default Apis;