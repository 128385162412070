import React, { Component } from "react";
import {

  Nav,

  NavDropdown,
  MenuItem


} from "react-bootstrap";
import { userService } from 'helpers/user.service.jsx';
class HeaderLinks extends Component {
  constructor(props){
    super(props);

            this.state = {
         FirstName:'',
         LastName:''
            }
           
          //  console.log('this.props', this.props)
        }

         
        componentDidMount() {
    
          userService.getinfo().then((data)=>{
          
          var user=JSON.parse(data);
      this.setState({FirstName:user.data.firstname,LastName:user.data.lastname})
          })
        }

  render() {
      return (
      <div>
       
        <Nav pullRight>
     
      
         
          <NavDropdown
            eventKey={1}
            title={
              <div>
              <i className="fad fa-user-circle"></i>
                <p className="hidden-md hidden-lg">
                  More
                  <b className="caret" />
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-3"
            bsClass="dropdown-with-icons dropdown"
          >
           <li className="namedisplay" ><span>{this.state.FirstName} {this.state.LastName}</span> </li>
           <MenuItem eventKey={1.2} href="/#/profile" >
           
           <div className="navbtn" >
          Profile
           </div>
         </MenuItem>
            <MenuItem eventKey={1.1} href="/#/a/login-page" >
           
              <div className="navbtn" >
               Sign out
              </div>
            </MenuItem>
          </NavDropdown>
        </Nav>
      </div>
    );
  }
}
export default HeaderLinks;
