import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckboxv2.jsx";
import Select from "react-select";
import { userService } from 'helpers/user.service.jsx';
const Config = require('config');


class Webhooks extends Component {
  constructor(props) {
    super(props);

    this.vForm = this.refs.vForm;
    this.state = {
      loading: true,
      alert: null,
      show: false,
      merchant_id:null,
 description:"",
 pos_url:"",
 widget_url:"",
 manual_url:"",
 pos_urlError: null,
 manual_urlError: null,
 widget_urlError: null,
 pos_weburl: Config.POSUrl


      
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidate = this.handleValidate.bind(this);

 
  }
  componentDidMount(){

    this.getposinfo();


  }

  getposinfo(){

    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);
      var auid=user.data.user_id;
    const requestOptions = {
      headers: { 'access-token': user.token,'mid':user.data.merchant_id ,'auid': auid},
    };

    fetch(Config.API+'/api/webhooks/',requestOptions)
    .then( this.handleResponse)
     .then( webhooks=> this.setState({
        pos_url:webhooks.data.pos_url,
        widget_url:webhooks.data.widget_url,
        manual_url:webhooks.data.manual_url,
         loading: false},()=>{
         }));

        }) 
  
    }


    updatWebhookinfo(pos_url,widget_url,manual_url){

      userService.getinfo().then((data)=>{

        var user=JSON.parse(data);
    
   
      var token=user.token;
      var auid=user.data.user_id;
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','access-token': token,'mid':user.data.merchant_id,'auid': auid},
         body: JSON.stringify({ pos_url,widget_url,manual_url}
          )
      };
          
    
  return fetch(Config.API+`/api/webhooks/update`,requestOptions)
     .then( this.handleResponse)
     .then(this.props.handleClick("tr","success","Webhooks info has been updated",<span data-notify='icon' className='pe-7s-check' />))
    
    })
  }


  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{
          if(data.status=='error'){ 
          userService.logout()
         window.location.reload(true);
            return 'error'
           
          }

        

        }
       

        return data;
    });
}


    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
   
  }



  



  handleValidate(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    
    

      if(this.state.pos_url!=='')
    
      { if(!this.state.pos_url.match(/^(http|https):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,;]+([\-\.]{1}[-a-zA-Z0-9+&@#/%?=~_|]+)*\.[a-zA-Z]{2,20}(:[0-9]{1,5})?(\/.*)?$/)){this.setState({
        pos_urlError: (
      <small className="text-danger">Please enter a valid URL</small>
      )
      })}else{this.setState({  pos_urlError: null })}
     
    }else{this.setState({  pos_urlError: null })}


    if(this.state.manual_url!=='')
    
    { if(!this.state.manual_url.match(/^(http|https):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,;]+([\-\.]{1}[-a-zA-Z0-9+&@#/%?=~_|]+)*\.[a-zA-Z]{2,20}(:[0-9]{1,5})?(\/.*)?$/)){this.setState({
      manual_urlError: (
    <small className="text-danger">Please enter a valid URL</small>
    )
    })}else{this.setState({  manual_urlError: null })}
   
  }else{this.setState({  manual_urlError: null })}

  if(this.state.widget_url!=='')
    
  { if(!this.state.widget_url.match(/^(http|https):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,;]+([\-\.]{1}[-a-zA-Z0-9+&@#/%?=~_|]+)*\.[a-zA-Z]{2,20}(:[0-9]{1,5})?(\/.*)?$/)){this.setState({
    widget_urlError: (
  <small className="text-danger">Please enter a valid URL</small>
  )
  })}else{this.setState({  widget_urlError: null })}
 
}else{this.setState({  widget_urlError: null })}


  
    
          


  }

  handleSubmit(e) {
    e.preventDefault();
    const { pos_url,widget_url,manual_url,pos_weburl} = this.state;
   if(  this.state.pos_urlError  === null && this.state.manual_urlError  === null &&  this.state.widget_urlError  === null  ){

  this.updatWebhookinfo(pos_url,widget_url,manual_url)}

   }



  render() {
    const { widget_url,pos_url,manual_url,loading,pos_weburl} = this.state;
   
    if (loading) return <Loader />
   return (   <div className="main-content">
                      
        <Grid fluid>
       
          <Row>
          <Col lg={12}>  <h3>Webhook Settings</h3> </Col>
       <Breadcrumb>
            <Breadcrumb.Item href="/#/settings">Settings</Breadcrumb.Item>
        
          </Breadcrumb>



  
         

                    <Col md={12}> 
              <Form horizontal onSubmit={this.handleSubmit}>
                <Card
                
                  content={
                    <div>
                     

                


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    Address Webhook URL (IPN) <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
        Notifications will be sent to the provided url.
        This field is optional
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="manual_url"
                          value={manual_url}
                          placeholder="https://yourwebhookurl.com"
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.manual_urlError}
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                     Widget Webhook URL (IPN) <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
        Notifications will be sent to the provided url.
        This field is optional
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="widget_url"
                          value={widget_url}
                          placeholder="https://yourwebhookurl.com"
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.widget_urlError}
                        </Col>
                      </FormGroup>

{ pos_weburl!=''
?                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       POS Webhook URL (IPN) <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
        Notifications will be sent to the provided url.
        This field is optional
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="pos_url"
                          value={pos_url}
                          placeholder="https://yourwebhookurl.com"
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.pos_urlError}
                        </Col>
                      </FormGroup>
:''}
                  
                    
                    
              

                   
                    
                    </div>
                  }
      
                  legend={
                    <Button
                      fill
                      bsStyle="info"
                      type="submit"
                      onClick={this.handleValidate}
                    >
                     Update
                    </Button>
                  }
                />
              </Form>
            </Col>
 
         
                 
                  </Row>
               
        </Grid>
      </div>
    );
  }
}

export default Webhooks ;
