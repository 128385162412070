import LoginPage from "views/Pages/LoginPage.jsx";
import ForgotPassword from "views/Pages/ForgotPassword.jsx";
import ResetPassword from "views/Pages/ResetPassword.jsx";


var pagesRoutes = [
  {
    path: "/a/login-page",
    name: "Login Page",
    mini: "LP",
    component: LoginPage
  },
  
  {path: "/a/forgot-password",
  name: "Forgot Password",
  mini: "LP",
  component: ForgotPassword
},
{path: "/a/password-reset/:userid/:token",
name: "Reset Password",
mini: "LP",
component: ResetPassword
}
];

export default pagesRoutes;
